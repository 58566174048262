export const formatPrice = (cents: number, decimals = false, currencyPosition = 'left'): string => {
  if (decimals) {
    const price = (cents / 100).toFixed(2)
    const formattedPrice = price
      .split('.')
      .join(',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')

    return currencyPosition === 'left' ? `€ ${formattedPrice}` : `${formattedPrice} €`
  } else {
    const price = (cents / 100).toString()
    const formattedPrice = price
      .split('.')
      .join(',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')

    return currencyPosition === 'left' ? `€ ${formattedPrice}` : `${formattedPrice} €`
  }
}
