import { Item } from '.'
import { Menu, MenuList } from './Menu'
import { Radio } from '@gassan-ui'
import { FC } from 'react'
import { GetItemPropsOptions, GetMenuPropsOptions, GetPropsCommonOptions } from 'downshift'

export type FilterMenuRadioListProps = {
  items: Item[]
  value: string | number
  variant?: 'align-right' | 'align-left'
  getMenuProps: (options?: GetMenuPropsOptions, otherOptions?: GetPropsCommonOptions) => any
  getItemProps: (options: GetItemPropsOptions<Item>) => any
}
export const FilterMenuRadioList: FC<FilterMenuRadioListProps> = ({
  items,
  value,
  variant = 'align-left',
  getMenuProps,
  getItemProps,
}) => {
  return (
    <Menu {...getMenuProps()} pt="1rem" variant={variant}>
      <MenuList>
        {items.map((item, index) => (
          <li {...getItemProps({ item, index })} key={`item-${index}`}>
            <Radio
              id={item.value.toString()}
              label={item.label}
              value={item.value}
              checked={value ? value === item.value : false}
              readOnly
              onClick={() => {}}
              mb={index === items.length - 1 ? 0 : '.5rem'}
            />
          </li>
        ))}
      </MenuList>
    </Menu>
  )
}
