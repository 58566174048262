import { Item } from '.'
import { FilterMenuButton } from './FilterMenuButton'
import { Box } from '@gassan-ui'
import Downshift from 'downshift'
import { FC } from 'react'
import { MarginBottomProps, MarginTopProps } from 'styled-system'
import { FilterMenuRadioList } from './FilterMenuRadioList'

export type FilterMenuRadioProps = MarginBottomProps &
  MarginTopProps & {
    items: Item[]
    value: string | number
    onChange: (value: string) => void
    label: string
    variant?: 'align-right' | 'align-left'
  }

export const FilterMenuRadio: FC<FilterMenuRadioProps> = ({
  items,
  value,
  onChange,
  label,
  mb = '1rem',
  mt,
  variant = 'align-left',
}) => {
  return (
    <Downshift
      itemToString={(item) => (item ? item.value : null)}
      onSelect={(item) => {
        if (item) {
          onChange(item.value)
        }
      }}
      selectedItem={null}
    >
      {({ getToggleButtonProps, getMenuProps, getRootProps, getItemProps, isOpen }) => {
        return (
          <Box {...getRootProps()} position="relative" display="inline-block" mb={mb} mt={mt}>
            <FilterMenuButton {...getToggleButtonProps()} isExpanded={isOpen} variant={variant}>
              {label}
            </FilterMenuButton>
            {isOpen && (
              <FilterMenuRadioList
                items={items}
                value={value}
                getMenuProps={getMenuProps}
                getItemProps={getItemProps}
                variant={variant}
              />
            )}
          </Box>
        )
      }}
    </Downshift>
  )
}
